@font-face {
  font-family: 'ApexNew Book';
  src: url('/public/fonts/ApexNew-Book/ApexNew-Book.eot');
  /* IE9 Compat Modes */
  src: url('/public/fonts/ApexNew-Book/ApexNew-Book.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/public/fonts/ApexNew-Book/ApexNew-Book.otf') format('otf'),
    /* Legacy iOS */
    url('/public/fonts/ApexNew-Book/ApexNew-Book.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-Book/ApexNew-Book.woff2') format('woff2'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-Book/ApexNew-Book.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'ApexNew Book Italic';
  src: url('/public/fonts/ApexNew-BookItalic/ApexNew-BookItalic.eot');
  /* IE9 Compat Modes */
  src: url('/public/fonts/ApexNew-BookItalic/ApexNew-BookItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/public/fonts/ApexNew-BookItalic/ApexNew-BookItalic.otf') format('otf'),
    /* Legacy iOS */
    url('/public/fonts/ApexNew-BookItalic/ApexNew-BookItalic.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-BookItalic/ApexNew-BookItalic.woff2') format('woff2'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-BookItalic/ApexNew-BookItalic.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'ApexNew Medium';
  src: url('/public/fonts/ApexNew-Medium/ApexNew-Medium.eot');
  /* IE9 Compat Modes */
  src: url('/public/fonts/ApexNew-Medium/ApexNew-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/public/fonts/ApexNew-Medium/ApexNew-Medium.otf') format('otf'),
    /* Legacy iOS */
    url('/public/fonts/ApexNew-Medium/ApexNew-Medium.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-Medium/ApexNew-Medium.woff2') format('woff2'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-Medium/ApexNew-Medium.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'ApexNew Bold';
  src: url('/public/fonts/ApexNew-Bold/ApexNew-Bold.eot');
  /* IE9 Compat Modes */
  src: url('/public/fonts/ApexNew-Bold/ApexNew-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/public/fonts/ApexNew-Bold/ApexNew-Bold.otf') format('otf'),
    /* Legacy iOS */
    url('/public/fonts/ApexNew-Bold/ApexNew-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-Bold/ApexNew-Bold.woff2') format('woff2'),
    /* Pretty Modern Browsers */
    url('/public/fonts/ApexNew-Bold/ApexNew-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

* {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.breakline {
  white-space: pre-wrap;
}

body {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

p {
  line-height: 1.5em;
}

h2 {
  font-family: 'ApexNew Book';
  line-height: 1.5em;
  margin-block-start: 5px !important;    
  margin-block-end: 5px !important;
}

h3 {
  font-family: 'ApexNew Book';
  margin-block-start: 0 !important;    
  margin-block-end: 0 !important;
}

h4 {
  font-family: 'ApexNew Book';
  margin-block-start: 5px !important;    
  margin-block-end: 5px !important;
}

button {
  cursor: pointer;
}

button.btn-read-more {
  font-family: 'ApexNew Medium';
  padding-top: 5px;
}

.text-center {
  text-align: center;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

@media (max-width: 769px) {
  p {
      font-size: 16px;
  }
  button {
    font-family: 'ApexNew Medium';
    padding-top: 10px !important;
  }
}