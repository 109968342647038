footer {
    background-color: #F1F1F1;
}

.footer-social-container {
    padding: 75px 0 0 0;
}

.info-contact-container {
    margin-top: 75px;
    background-color: #d0d600;
    padding: 75px 75px;
}
.logo-ipi {
    display: block;
    margin: auto;
    width: 275px;
}

ul.info-list {
    text-align: center;
    list-style: none;
    padding-inline-start: 0 !important;
}

li.contact-info {
    font-family: 'ApexNew Book';
    font-size: 18px;
    font-weight: 500;
    color: #757575;
    letter-spacing: 2px;
    transition: letter-spacing 0.5s linear;
    cursor: pointer; 
    padding-top: 10px;
}

li.contact-info:hover {
    font-family: 'ApexNew Book';
    font-weight: 600;
    letter-spacing: 3px;
}

.eventbrite-container {
    background-color: #EF7C2D;
    padding: 75px 0;
}

img.eventbrite-footer {
    width: 200px;
    display: block;
    margin: auto;
}

.social-title {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    padding-bottom: 1em;
    letter-spacing: 2px;
}

.social-icons-container {
    display: flex;
    justify-content: center;
}

.social-icons {
    width: 40px;
    margin-left: 15px;
}

img.heart {
    display: block;
    margin: auto;
    padding-top: 50px;
}

.mt-75 {
    margin-top: 75px;
}

@media (max-width: 769px) {
    .info-contact-container {
        padding: 75px 15px;
    }
    img.heart {
        width: 175px;
    }
    li.contact-info {
        font-size: 14px;
    }
    
}