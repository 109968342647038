.circle-img {
    width: 400px;
    display: block;
    margin: auto;
}
p.content {
    font-family: 'ApexNew Book';
    font-size: 18px;
    text-align: center;
}

h2.title-biografia {
    font-size: 20px;
}
.span-h4 {
    font-size: 16px;
}
.text-grey {
    color: #757575;
}
.btn-read-more {
    display: block;
    margin: auto;
}

.mb {
    margin-bottom: 75px;
}

.mt {
    margin-top: 25px !important;
}
.bio-container {
    padding: 75px 75px;
}


@media (max-width: 769px) {
    .circle-img {
        width: 275px;
    }
    p.content {
        font-size: 16px;
    }
    .mb {
        margin-bottom: 35px;
    }
    .bio-container {
        padding: 75px 15px;
    }

}