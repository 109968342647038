.border-bottom {
    border-bottom: none !important;
}
.eventbrite-img {
    width: 150px;
    padding-top: 35px;
}

.lang-links-container {
    width: 100%;
    display: flex;
    padding-top: 35px;
    justify-content: space-around;
}

.lang-items {
    font-family: 'ApexNew Medium';
    color: #757575;
    padding: 0 15px 0 15px;
    text-decoration: none;
}
.lang-items:hover {
    font-family: 'ApexNew Bold';
    color: #757575;
}


.lang-items:hover {
    text-decoration: underline;
}