.five-modules-container {
    padding: 75px;
    background-color: #F1F1F1;
}
h2.title-five-modules {
    font-size: 20px;
}

@media (max-width: 769px) {
    .five-modules-container {
        padding: 75px 15px;
    }
    h2.title-five-modules {
        font-size: 18px;
    }
}