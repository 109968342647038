.intro-container {
    padding: 0 75px 75px 75px;
}

.half-circle {
    width: 15rem;
    height: 7.5rem;
    background-color: #efefef;
    border-radius: 10rem 10rem 0 0;   
    margin: 0 75px;
    transform: rotate(180deg);
}

.circle-content {
    position: absolute;
    transform: rotate(180deg) !important;
    padding-bottom: 45px;
    padding-right: 45px;
}

.circle-text-one {
    font-size: 22px;
    font-family: 'ApexNew Book';
    font-weight: 700;
    text-align: center;
}

.circle-text-two {
    font-size: 18px;
    font-family: 'ApexNew Book';
    text-align: center;
}

img.logo-intro {
    width: 35%;
    display: block;
    margin: auto;
    padding-bottom: 50px;
}

p.intro {
    font-family: 'ApexNew Book Italic';
    font-size: 24px;
    text-align: center;
    width: 90%;
    display: block;
    margin: auto;
}

p.dr-norman {
    font-family: 'ApexNew Medium';
    font-size: 24px;
    text-align: center;
    padding-top: 10px;
}

.container-circles {
    padding: 0 75px;
}

.intro-circles-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    justify-items: center;
}

img.modules-circles {
    width: 100%;
    padding-top: 40px;
}

.eventbrite-intro {
    width: 200px;
    padding-top: 35px;
    display: block;
    margin: auto;
}

.lang-links-container-intro {
    width: 100%;
    display: flex;
    padding-top: 35px;
    justify-content: center;
}

.lang-items-intro {
    font-family: 'ApexNew Medium';
    color: #939393;
    padding: 0 15px 0 15px;
    text-decoration: none;
}

.lang-items-intro:hover {
    font-family: 'ApexNew Bold';
    text-decoration: underline;
    color: #757575;
}

@media (max-width: 769px) {
    .intro-container {
        padding: 0 15px 75px 15px;
    }

    .container-circles {
        padding: 0;
    }

    .intro-circles-grid {
        gap: 5px;
    }

    img.logo-intro {
        width: 90% !important;
        padding-bottom: 50px !important;
        padding-top: 50px;
    }

    p.intro {
        font-size: 16px;
    }

    p.dr-norman {
        font-family: 'ApexNew Medium';
        font-size: 18px;
        text-align: center;
    }

    img.modules-circles {
        width: 100% !important;
        padding-bottom: 15px;
    }
}

@media (max-width: 100px) {
    img.modules-circles {
        width: 75%;
    }

}

@media (max-width: 1200px){
    .half-circle {
        margin: 0 !important;
    }
}