.img-modulo {
    object-fit: cover;
    object-position: bottom;
    width: 100%;
    height: 500PX;
}
.img-modulo-mobile {
    display: none;
}      


.container-text-modulo {
    padding: 25px 75px 150px 75px;
}

.pink-btn {
    font-size: 16px;
    background-color: #CC96B6;
    color: #fff;
    border: solid 1px #CC96B6;
    border-radius: 10px;
    padding: 0 40px 5px 40px;
    transition: background-color 0.3s;
}
span.bold {
    font-weight: 700;
}


@media (max-width: 769px) {
    .container-text-modulo {
        padding: 15px 15px 75px 15px;
    }  
    .img-modulo-mobile {
        object-fit: cover;
        width: 100%;
        display: block !important;
    }  
    .img-modulo {
        display: none;
    }    
}